import getImageReader from 'helpers/GetImageReader';
import { get } from 'helpers/api_helper';

const BASE_URL = '/customer-service';

const getAttachmentImage = async (ticketId, articleId, attachmentId) => {
  try {
    const response = await get(`${BASE_URL}/${ticketId}/${articleId}/${attachmentId}`, {
      responseType: 'blob',
    });

    const imageData = await getImageReader(response);
    return imageData;
  } catch (error) {
    return null;
  }
};

export default getAttachmentImage;
