import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Button,
  Badge,
  Spinner,
} from 'reactstrap';
import toast from 'react-hot-toast';
import MetaTags from 'react-meta-tags';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

import { ticketService } from '../../services/ticketService';
import TicketMessage from './components/TicketMessage';
import './support.scss';

const TicketPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [ticket, setTicket] = useState(null);
  const [articles, setArticles] = useState([]);
  const [states, setStates] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [newFiles, setNewFiles] = useState([]);
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(true);
  const [messageError, setMessageError] = useState('');
  const messagesContainerRef = useRef(null);
  const articleCountRef = useRef(null);
  const isSyncingRef = useRef(false);
  const isUpdatingRef = useRef(false);

  const allowedTypes = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png', 'image/webp'];

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      const container = messagesContainerRef.current;
      setTimeout(() => {
        container.scrollTop = container.scrollHeight;
      }, 50);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [articles]);

  useEffect(() => {
    const fetchStatusesAndPriorities = async () => {
      try {
        const [statesResponse, prioritiesResponse] = await Promise.all([
          ticketService.getTicketStates(),
          ticketService.getTicketPriorities(),
        ]);
        setStates(statesResponse);
        setPriorities(prioritiesResponse);
      } catch (error) {
        console.error('Error loading statuses and priorities:', error);
      }
    };
    fetchStatusesAndPriorities();
  }, []);

  const syncMessages = async (expectedCount) => {
    try {
      isSyncingRef.current = true;
      let attempts = 0;
      const maxAttempts = 5;

      while (attempts < maxAttempts) {
        const articlesResponse = await ticketService.getTicketArticles(id);
        const actualCount = articlesResponse?.ticket_articles?.length || 0;

        if (actualCount === expectedCount) {
          const sortedArticles = Array.isArray(articlesResponse?.ticket_articles)
            ? [...articlesResponse.ticket_articles].sort(
                (a, b) => new Date(a.created_at) - new Date(b.created_at),
              )
            : [];
          setArticles(sortedArticles);
          setTimeout(scrollToBottom, 100);
          break;
        }

        await new Promise((resolve) => setTimeout(resolve, 10000));
        attempts++;
      }
    } finally {
      isSyncingRef.current = false;
    }
  };

  useEffect(() => {
    const checkNewMessages = async () => {
      try {
        if (isUpdatingRef.current || sending || isSyncingRef.current) {
          return;
        }

        const ticketResponse = await ticketService.getTicket(id);

        if (
          articleCountRef.current !== null &&
          ticketResponse.article_count !== articleCountRef.current
        ) {
          isUpdatingRef.current = true;
          setTicket(ticketResponse);

          await syncMessages(ticketResponse.article_count);

          isUpdatingRef.current = false;
        }

        articleCountRef.current = ticketResponse.article_count;
      } catch (error) {
        console.error('Error checking new messages:', error);
      }
    };

    checkNewMessages();

    const interval = setInterval(checkNewMessages, 10000);

    return () => clearInterval(interval);
  }, [id, sending]);

  useEffect(() => {
    const fetchTicketData = async () => {
      try {
        setLoading(true);
        const [ticketResponse, articlesResponse] = await Promise.all([
          ticketService.getTicket(id),
          ticketService.getTicketArticles(id),
        ]);

        if (!ticketResponse) {
          history.push('/support');
          return;
        }

        setTicket(ticketResponse);
        const sortedArticles = Array.isArray(articlesResponse?.ticket_articles)
          ? [...articlesResponse.ticket_articles].sort(
              (a, b) => new Date(a.created_at) - new Date(b.created_at),
            )
          : [];
        setArticles(sortedArticles);

        articleCountRef.current = ticketResponse.article_count;

        setTimeout(scrollToBottom, 100);
      } catch (error) {
        console.error('Error loading ticket data:', error);
        history.push('/support');
      } finally {
        setLoading(false);
      }
    };
    fetchTicketData();
  }, [id]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files).filter((file) => {
      if (!allowedTypes.includes(file.type)) {
        toast.error(t('support_ticket_file_type_error'));
        return false;
      }
      return true;
    });
    setNewFiles((prev) => [...prev, ...files]);
  };

  const handleRemoveFile = (index) => {
    setNewFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) {
      setMessageError(t('support_ticket_message_required'));
      return;
    }

    if (!newMessage.trim() && newFiles.length === 0) return;

    setSending(true);
    setMessageError('');
    try {
      const formData = new FormData();
      formData.append('ticket_id', id);
      formData.append('body', newMessage);
      formData.append('subject', 'Re: ' + ticket.title);

      newFiles.forEach((file) => {
        formData.append('files', file);
      });

      await ticketService.createArticleWithAttachments(formData);

      const [ticketResponse, articlesResponse] = await Promise.all([
        ticketService.getTicket(id),
        ticketService.getTicketArticles(id),
      ]);

      setTicket(ticketResponse);
      const sortedArticles = Array.isArray(articlesResponse?.ticket_articles)
        ? [...articlesResponse.ticket_articles].sort(
            (a, b) => new Date(a.created_at) - new Date(b.created_at),
          )
        : [];
      setArticles(sortedArticles);

      articleCountRef.current = ticketResponse.article_count;

      setNewMessage('');
      setNewFiles([]);
      setMessageError('');

      setTimeout(scrollToBottom, 100);
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setSending(false);
    }
  };

  const getTicketState = (stateId) => {
    const state = states?.find((s) => s.id === stateId);
    return state?.name || 'unknown';
  };

  const getTicketPriority = (priorityId) => {
    const priority = priorities?.find((p) => p.id === priorityId);
    return priority?.name || 'normal';
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'd MMM yyyy HH:mm', { locale: ru });
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  if (loading) {
    return (
      <div className="page-content">
        <MetaTags>
          <title>{t('support_ticket_details')} | MC</title>
        </MetaTags>

        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <div className="text-center p-4">
                    <Spinner color="primary" />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  if (!ticket) {
    return (
      <div className="page-content">
        <MetaTags>
          <title>{t('support_ticket_details')} | MC</title>
        </MetaTags>

        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <div className="text-center p-4">
                    <p>{t('support_ticket_not_found')}</p>
                    <Button color="primary" onClick={() => history.push('/support')}>
                      {t('support_back_to_list')}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>{t('support_ticket_details')} | MC</title>
      </MetaTags>

      <Container fluid>
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <div className="page-header">
                  <div className="header-text">
                    <Button
                      color="link"
                      className="back-button p-0"
                      onClick={() => history.push('/support')}>
                      <i className="bx bx-arrow-back"></i>
                    </Button>
                    <div className="d-flex align-items-center">
                      <h4 className="mb-0">
                        #{ticket.number} - {ticket.title}
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="ticket-status-section mb-3">
                  <Badge
                    color={ticket.state_id === 2 ? 'success' : 'secondary'}
                    className="me-2 fs-6 px-3 py-2">
                    {t(`support_status_${getTicketState(ticket.state_id)}`)}
                  </Badge>
                  <Badge color="info" className="fs-6 px-3 py-2">
                    {t(`support_priorities_${getTicketPriority(ticket.priority_id)}`)}
                  </Badge>
                </div>

                <div className="ticket-dates mb-4">
                  <div>
                    {t('support_ticket_created_at')}: {formatDate(ticket.created_at)}
                  </div>
                  <div>
                    {t('support_ticket_last_update')}: {formatDate(ticket.updated_at)}
                  </div>
                </div>

                <Card className="mb-4">
                  <CardBody>
                    <h5 className="mb-3">{t('support_ticket_history')}</h5>
                    <div
                      className="messages-container"
                      style={{ maxHeight: '400px', overflowY: 'auto' }}
                      ref={messagesContainerRef}>
                      {Array.isArray(articles) &&
                        articles.map((article) => (
                          <TicketMessage
                            key={article.id}
                            article={article}
                            onDownloadAttachment={(attachmentId) =>
                              ticketService.getArticleAttachment(
                                ticket.id,
                                article.id,
                                attachmentId
                              )
                            }
                            formatDate={formatDate}
                            formatFileSize={formatFileSize}
                          />
                        ))}
                    </div>

                    <div className="reply-form">
                      <Form onSubmit={handleSubmit}>
                        <div className="form-group position-relative">
                          <Input
                            type="textarea"
                            value={newMessage}
                            onChange={(e) => {
                              setNewMessage(e.target.value);
                              setMessageError('');
                            }}
                            placeholder={t('support_ticket_message_placeholder')}
                            rows="3"
                            className={`mb-2 ${messageError ? 'is-invalid' : ''}`}
                          />
                          {messageError && (
                            <div className="invalid-feedback d-block mb-2">
                              {messageError}
                            </div>
                          )}
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <div className="position-relative">
                                <Input
                                  id="files"
                                  type="file"
                                  multiple
                                  onChange={handleFileChange}
                                  className="d-none"
                                  accept=".jpg,.jpeg,.gif,.png,.webp"
                                />
                                <Button
                                  color="light"
                                  size="sm"
                                  className="d-flex align-items-center"
                                  onClick={() => document.getElementById('files').click()}
                                >
                                  <i className="bx bx-paperclip me-1"></i>
                                  {t('support_ticket_add_attachments')}
                                  {newFiles.length > 0 && (
                                    <span className="badge bg-primary rounded-pill ms-1">
                                      {newFiles.length}
                                    </span>
                                  )}
                                </Button>
                              </div>
                              {newFiles.length > 0 && (
                                <div className="ms-2 small text-muted">
                                  {newFiles.map((file, index) => (
                                    <span key={index} className="me-2">
                                      {file.name}
                                      <Button
                                        color="link"
                                        className="p-0 ms-1 text-danger"
                                        onClick={() => handleRemoveFile(index)}
                                        style={{ fontSize: '14px' }}
                                      >
                                        <i className="bx bx-x"></i>
                                      </Button>
                                    </span>
                                  ))}
                                </div>
                              )}
                            </div>
                            <Button
                              type="submit"
                              style={{ background: '#13b9ae', border: 'none' }}
                              disabled={sending || (!newMessage.trim() && newFiles.length === 0)}
                            >
                              {sending ? t('support_ticket_sending') : t('support_ticket_send')}
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TicketPage;
