import { t } from 'i18next';
import toast from 'react-hot-toast';
import { all, fork, put, takeEvery } from 'redux-saga/effects';

import {
  addNotificationRequest,
  deleteNotificationRequest,
  editNotificationRequest,
  getAllNotificationsRequest,
  getNotificationsSettingsRequest,
  setNotificationsSettingsRequest,
} from 'services/notificationsService';

import { get } from '../../helpers/api_helper';
import { apiFail, apiSuccess } from '../common/actions';
import {
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  EDIT_NOTIFICATION,
  GET_ALL_NOTIFICATIONS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SETTINGS,
  SET_NOTIFICATIONS_SETTINGS,
} from './actionTypes';

function* getNotifications({ payload }) {
  try {
    const { locale, take, skip } = payload;
    const response = yield get(
      `/notifications?locale=${locale || 'en'}&take=${take || 10}&skip=${skip || 0}`,
    );
    yield put(apiSuccess(GET_NOTIFICATIONS, response));
  } catch (error) {
    yield put(apiFail(GET_NOTIFICATIONS, error));
  }
}

function* getAllNotifications({ payload }) {
  try {
    const { locale, take, skip } = payload;
    const response = yield getAllNotificationsRequest(
      `${locale || 'en'}&take=${take || 10}&skip=${skip || 0}`,
    );
    yield put(apiSuccess(GET_ALL_NOTIFICATIONS, response));
  } catch (error) {
    yield put(apiFail(GET_ALL_NOTIFICATIONS, error));
  }
}

function* addNotification({ payload }) {
  try {
    const response = yield addNotificationRequest(payload.data);
    yield put(apiSuccess(ADD_NOTIFICATION, response.data));
    yield payload.redirectHandler();
    toast.success(t('api_create_notification_success'));
  } catch (error) {
    toast.error(t('api_create_notification_error'));
  }
}

function* editNotification({ payload }) {
  try {
    const response = yield editNotificationRequest(payload.data);
    yield put(apiSuccess(EDIT_NOTIFICATION, response.data));
    toast.success(t('api_edit_notification_success'));
    yield payload.redirectHandler();
  } catch (error) {
    toast.error(t('api_edit_notification_error'));
  }
}

function* deleteNotification({ payload }) {
  try {
    yield deleteNotificationRequest({ id: payload.id });
    yield put(apiSuccess(DELETE_NOTIFICATION, payload.id));

    const response = yield getAllNotificationsRequest(
      `${payload?.language || 'en'}&take=${payload?.take || 10}&skip=${payload?.skip || 0}`,
    );

    yield put(apiSuccess(GET_ALL_NOTIFICATIONS, response));
    toast.success(t('api_delete_notification_success'));
  } catch (error) {
    toast.error(t('api_delete_notification_error'));
  }
}

function* getNotificationsSettings({ payload }) {
  try {
    const response = yield getNotificationsSettingsRequest(payload);
    yield put(apiSuccess(GET_NOTIFICATIONS_SETTINGS, response));
  } catch (error) {
    yield put(apiFail(GET_NOTIFICATIONS_SETTINGS, error));
  }
}

function* setNotificationsSettings({ payload }) {
  try {
    const response = yield setNotificationsSettingsRequest(payload);
    yield put(apiSuccess(SET_NOTIFICATIONS_SETTINGS, response));

    toast.success(t('notification_settings_save_success'));
  } catch (error) {
    yield put(apiFail(SET_NOTIFICATIONS_SETTINGS, error));
    toast.error(t('notification_settings_save_error'));
  }
}

export function* watchNotificaions() {
  yield takeEvery(GET_NOTIFICATIONS, getNotifications);
  yield takeEvery(GET_ALL_NOTIFICATIONS, getAllNotifications);
  yield takeEvery(ADD_NOTIFICATION, addNotification);
  yield takeEvery(EDIT_NOTIFICATION, editNotification);
  yield takeEvery(DELETE_NOTIFICATION, deleteNotification);
  yield takeEvery(GET_NOTIFICATIONS_SETTINGS, getNotificationsSettings);
  yield takeEvery(SET_NOTIFICATIONS_SETTINGS, setNotificationsSettings);
}

function* notificationsSaga() {
  yield all([fork(watchNotificaions)]);
}

export default notificationsSaga;
