import React, { useEffect, useState } from 'react';

import getAttachmentImage from 'helpers/GetAttachmentImage';
import parse, { Element } from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import ImagePreview from './ImagePreview';

const TicketMessage = ({ article, onDownloadAttachment, formatDate, formatFileSize }) => {
  const { t } = useTranslation();
  const [previewImage, setPreviewImage] = useState(null);
  const [attachmentImages, setAttachmentImages] = useState({});
  const isCustomer = article.sender === 'Customer';

  useEffect(() => {
    const loadAttachmentImages = async () => {
      if (!article.attachments) return;

      const imageAttachments = article.attachments.filter((attachment) =>
        attachment.preferences['Content-Type']?.startsWith('image/'),
      );

      const images = {};
      for (const attachment of imageAttachments) {
        try {
          const imageData = await getAttachmentImage(article.ticket_id, article.id, attachment.id);
          if (imageData) {
            images[attachment.id] = imageData;
          }
        } catch (error) {
          console.error('Error loading attachment image:', error);
        }
      }
      setAttachmentImages(images);
    };

    loadAttachmentImages();
  }, [article]);

  const handleImageClick = (imageData) => {
    setPreviewImage(imageData);
  };

  const renderMessageContent = () => {
    if (article.content_type === 'text/html') {
      const options = {
        replace: (domNode) => {
          if (domNode instanceof Element && domNode.name === 'img') {
            return <></>;
          }
        },
      };

      return <div>{parse(article.body, options)}</div>;
    }
    return <div>{article.body}</div>;
  };

  const renderCustomerAttachments = () => {
    if (!article.attachments || article.attachments.length === 0) return null;

    return (
      <div className="message-attachments mt-3">
        <div className="attachments-header">
          <i className="bx bx-paperclip"></i>
          <span className="ms-1">
            {article.attachments.length} {t('support_ticket_attachments')}
          </span>
        </div>
        <div className="attachments-list">
          {article.attachments.map((attachment) => {
            const isImage = attachment.preferences['Content-Type']?.startsWith('image/');
            const imageData = isImage ? attachmentImages[attachment.id] : null;

            return (
              <div
                key={attachment.id}
                className="attachment-item"
                onClick={() =>
                  isImage ? handleImageClick(imageData) : onDownloadAttachment(attachment.id)
                }>
                <div className="attachment-icon">
                  <i className={`bx ${isImage ? 'bx-image' : 'bx-file'}`}></i>
                </div>
                <div className="attachment-info">
                  <div className="attachment-name">{attachment.filename}</div>
                  <div className="attachment-size">{formatFileSize(attachment.size)}</div>
                </div>
                <div className="attachment-download">
                  <i className="bx bx-download"></i>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderAgentAttachments = () => {
    if (!article.attachments) return null;

    const inlineImages = article.attachments.filter((attachment) =>
      attachment.preferences['Content-Type']?.startsWith('image/'),
    );

    const otherAttachments = article.attachments.filter(
      (attachment) => !attachment.preferences['Content-Type']?.startsWith('image/'),
    );

    return (
      <>
        {inlineImages.length > 0 && (
          <div className="inline-images mt-3">
            {inlineImages.map((attachment) => {
              const imageData = attachmentImages[attachment.id];
              if (!imageData) return null;

              return (
                <img
                  key={attachment.id}
                  src={imageData}
                  alt={attachment.filename}
                  className="inline-image me-2 mb-2"
                  onClick={() => handleImageClick(imageData)}
                />
              );
            })}
          </div>
        )}

        {otherAttachments.length > 0 && (
          <div className="message-attachments mt-3">
            <div className="attachments-header">
              <i className="bx bx-paperclip"></i>
              <span className="ms-1">
                {otherAttachments.length} {t('support_ticket_attachments')}
              </span>
            </div>
            <div className="attachments-list">
              {otherAttachments.map((attachment) => (
                <div
                  key={attachment.id}
                  className="attachment-item"
                  onClick={() => onDownloadAttachment(attachment.id)}>
                  <div className="attachment-icon">
                    <i className="bx bx-file"></i>
                  </div>
                  <div className="attachment-info">
                    <div className="attachment-name">{attachment.filename}</div>
                    <div className="attachment-size">{formatFileSize(attachment.size)}</div>
                  </div>
                  <div className="attachment-download">
                    <i className="bx bx-download"></i>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className={`ticket-message ${isCustomer ? 'customer' : 'agent'}`}>
        <div className="message-header">
          <div className="message-author">{isCustomer ? article.from : t('support_agent')}</div>
          <div className="message-time">{formatDate(article.created_at)}</div>
        </div>
        <div className="message-body">
          {renderMessageContent()}
          {isCustomer ? renderCustomerAttachments() : renderAgentAttachments()}
        </div>
      </div>

      <ImagePreview
        isOpen={!!previewImage}
        toggle={() => setPreviewImage(null)}
        imageUrl={previewImage}
      />
    </>
  );
};

export default TicketMessage;
