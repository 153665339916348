import React from 'react';
import PropTypes from 'prop-types';
import './TableSkeleton.scss';

const TableSkeleton = ({ rowsCount = 5, columnsCount = 4 }) => {
  return (
    <div className="table-skeleton">
      <div className="table-skeleton__header">
        {Array(columnsCount)
          .fill()
          .map((_, index) => (
            <div key={`header-${index}`} className="table-skeleton__cell table-skeleton__header-cell">
              <div className="table-skeleton__content" />
            </div>
          ))}
      </div>
      <div className="table-skeleton__body">
        {Array(rowsCount)
          .fill()
          .map((_, rowIndex) => (
            <div key={`row-${rowIndex}`} className="table-skeleton__row">
              {Array(columnsCount)
                .fill()
                .map((_, colIndex) => (
                  <div
                    key={`cell-${rowIndex}-${colIndex}`}
                    className="table-skeleton__cell table-skeleton__body-cell"
                  >
                    <div className="table-skeleton__content" />
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

TableSkeleton.propTypes = {
  rowsCount: PropTypes.number,
  columnsCount: PropTypes.number,
};

export default TableSkeleton;
