import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import toast from 'react-hot-toast';

const categories = {
  technical_issues: 'Технические проблемы',
  product_support: 'Поддержка продукта',
  payment_issues: 'Проблема с оплатой',
  other_issues: 'Другое',
};

const CreateTicketModal = ({
  isOpen,
  toggle,
  onSubmit,
  states,
  priorities,
  ticketService,
  onSuccess,
  setError,
}) => {
  const initialFormData = {
    title: '',
    body: '',
    category: '',
    priority_id: 2,
    group: 'Users',
    prima_role: 'investor',
    unread_messages: false,
    files: [],
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();

  const allowedTypes = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png', 'image/webp'];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === 'priority_id' ? Number(value) : value,
    }));

    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files).filter((file) => {
      if (!allowedTypes.includes(file.type)) {
        toast.error(t('support_ticket_file_type_error'));
        return false;
      }
      return true;
    });
    setFormData((prev) => ({ ...prev, files: [...prev.files, ...files] }));
  };

  const handleRemoveFile = (index) => {
    setFormData((prev) => ({
      ...prev,
      files: prev.files.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;

    const errors = {};
    if (!formData.title) {
      errors.title = t('support_form_title_required');
    }
    if (!formData.category) {
      errors.category = t('support_form_category_required');
    }
    if (!formData.body) {
      errors.body = t('support_form_message_required');
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    setSubmitting(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('title', formData.title);
      formDataToSend.append('body', formData.body);
      formDataToSend.append('category', formData.category);
      formDataToSend.append('priority_id', formData.priority_id);
      formDataToSend.append('group', formData.group);
      formDataToSend.append('prima_role', formData.prima_role);
      formDataToSend.append('unread_messages', formData.unread_messages);

      formData.files.forEach((file) => {
        formDataToSend.append('files', file);
      });

      await ticketService.createTicketWithAttachments(formDataToSend);
      setFormData(initialFormData);
      setErrors({});
      toggle();
      if (onSuccess) {
        onSuccess();
      }
      onSubmit(Promise.resolve());
    } catch (error) {
      console.error('Error creating ticket:', error);
      setError(t('error_creating_ticket'));
      onSubmit(Promise.reject(error));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle}>{t('support_form_title')}</ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody className="px-4">
          <FormGroup>
            <Label for="title">{t('support_form_subject')}</Label>
            <Input
              id="title"
              name="title"
              placeholder={t('support_form_subject_placeholder')}
              value={formData.title}
              onChange={handleChange}
              invalid={!!errors.title}
            />
            {errors.title && <div className="invalid-feedback d-block">{errors.title}</div>}
          </FormGroup>

          <div className="row">
            <div className="col-md-6">
              <FormGroup>
                <Label for="category" className="required">
                  {t('support_form_category')}
                </Label>
                <Input
                  id="category"
                  type="select"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  invalid={!!errors.category}>
                  <option value="">{t('support_form_category_placeholder')}</option>
                  {Object.entries(categories).map(([value, label]) => (
                    <option key={value} value={value}>
                      {t(`support_categories_${value}`)}
                    </option>
                  ))}
                </Input>
                {errors.category && (
                  <div className="invalid-feedback d-block">{errors.category}</div>
                )}
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup>
                <Label for="priority_id">{t('support_form_priority')}</Label>
                <Input
                  id="priority_id"
                  type="select"
                  name="priority_id"
                  value={formData.priority_id}
                  onChange={handleChange}
                  invalid={!!errors.priority_id}>
                  <option value="">{t('support_form_priority_placeholder')}</option>
                  {priorities?.map((priority) => (
                    <option key={priority.id} value={priority.id}>
                      {t(`support_priorities_${priority.name}`)}
                    </option>
                  ))}
                </Input>
                {errors.priority_id && (
                  <div className="invalid-feedback d-block">{errors.priority_id}</div>
                )}
              </FormGroup>
            </div>
          </div>

          <FormGroup>
            <Label for="body">{t('support_form_message')}</Label>
            <Input
              type="textarea"
              id="body"
              name="body"
              placeholder={t('support_form_message_placeholder')}
              value={formData.body}
              onChange={handleChange}
              rows="4"
              invalid={!!errors.body}
            />
            {errors.body && <div className="invalid-feedback d-block">{errors.body}</div>}
          </FormGroup>

          <FormGroup>
            <div className="d-flex align-items-center">
              <div className="position-relative">
                <Input
                  id="create-ticket-files"
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  className="d-none"
                  accept=".jpg,.jpeg,.gif,.png,.webp"
                />
                <Button
                  color="light"
                  size="sm"
                  className="d-flex align-items-center"
                  onClick={() => document.getElementById('create-ticket-files').click()}
                >
                  <i className="bx bx-paperclip me-1"></i>
                  {t('support_ticket_add_attachments')}
                  {formData.files.length > 0 && (
                    <span className="badge bg-primary rounded-pill ms-1">
                      {formData.files.length}
                    </span>
                  )}
                </Button>
              </div>
              {formData.files.length > 0 && (
                <div className="ms-2 small text-muted">
                  {formData.files.map((file, index) => (
                    <span key={index} className="me-2">
                      {file.name}
                      <Button
                        color="link"
                        className="p-0 ms-1 text-danger"
                        onClick={() => handleRemoveFile(index)}
                        style={{ fontSize: '14px' }}
                      >
                        <i className="bx bx-x"></i>
                      </Button>
                    </span>
                  ))}
                </div>
              )}
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={toggle}>
            {t('support_form_cancel')}
          </Button>
          <Button
            type="submit"
            style={{ background: '#13b9ae', border: 'none' }}
            disabled={submitting}>
            {submitting ? t('support_form_submitting') : t('support_form_submit')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreateTicketModal;
