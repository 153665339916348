import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap';

import { ticketService } from '../../services/ticketService';

import CreateTicketModal from './components/CreateTicketModal';
import TicketList from './components/TicketList';

import TicketPage from './TicketPage';

const SupportPage = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const [activeTab, setActiveTab] = useState('recent');
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [states, setStates] = useState([]);
  const [priorities, setPriorities] = useState([]);

  useEffect(() => {
    fetchStatesAndPriorities();
    fetchTickets();
  }, []);

  const fetchStatesAndPriorities = async () => {
    try {
      const [statesResponse, prioritiesResponse] = await Promise.all([
        ticketService.getTicketStates(),
        ticketService.getTicketPriorities(),
      ]);
      setStates(statesResponse);
      setPriorities(prioritiesResponse);
    } catch (error) {
      console.error('Error fetching states and priorities:', error);
    }
  };

  const fetchTickets = async () => {
    setLoading(true);
    try {
      const response = await ticketService.getTickets();
      setTickets(response.tickets);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateTicket = async (data) => {
    try {
      await data;
      setIsModalOpen(false);
      await fetchTickets();
    } catch (error) {
      console.error('Error creating ticket:', error);
    }
  };

  const toggle = () => setIsModalOpen(!isModalOpen);

  return (
    <div className="page-content">
      <MetaTags>
        <title>{t('support_title')} | MC</title>
      </MetaTags>

      <Container fluid>
        <Row>
          <Col xs={12}>
            <Switch>
              <Route exact path={path}>
                <Card>
                  <CardBody>
                    <div className="page-header w-100">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <div>
                          <h4 className="mb-0">{t('support_title')}</h4>
                          <p className="text-muted mb-0">{t('support_description')}</p>
                        </div>
                        <Button
                          style={{ background: '#13b9ae', color: 'white', border: 'none' }}
                          onClick={() => setIsModalOpen(true)}>
                          + {t('support_create_ticket')}
                        </Button>
                      </div>
                    </div>

                    <div className="status-tabs mb-4">
                      <div className="tabs-container">
                        <div
                          className={`tab-item ${activeTab === 'recent' ? 'active' : ''}`}
                          onClick={() => setActiveTab('recent')}>
                          {t('support_tabs_recent')}
                        </div>
                        <div
                          className={`tab-item ${activeTab === 'open' ? 'active' : ''}`}
                          onClick={() => setActiveTab('open')}>
                          {t('support_tabs_open')}
                        </div>
                        <div
                          className={`tab-item ${activeTab === 'closed' ? 'active' : ''}`}
                          onClick={() => setActiveTab('closed')}>
                          {t('support_tabs_closed')}
                        </div>
                      </div>
                    </div>

                    <TicketList
                      tickets={tickets}
                      loading={loading}
                      activeTab={activeTab}
                      states={states}
                      priorities={priorities}
                    />
                  </CardBody>
                </Card>
              </Route>
              <Route path={`${path}/ticket/:id`}>
                <TicketPage />
              </Route>
            </Switch>

            <CreateTicketModal
              isOpen={isModalOpen}
              toggle={toggle}
              onSubmit={handleCreateTicket}
              priorities={priorities}
              ticketService={ticketService}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SupportPage;
