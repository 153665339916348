import { get, post, postFormData } from '../helpers/api_helper';
import { API_URL } from '../config';

const BASE_URL = '/customer-service';

export const ticketService = {
    // Tickets
    getTickets: () => get(`${BASE_URL}/tickets`),
    getTicket: (ticketId) => get(`${BASE_URL}/tickets/${ticketId}`),
    createTicket: (data) => post(`${BASE_URL}/tickets`, data),
    
    // Articles (Messages)
    getTicketArticles: (ticketId) => get(`${BASE_URL}/tickets/${ticketId}/articles`),
    createTicketArticle: (data) => post(`${BASE_URL}/tickets/articles`, data),
    
    // States and Priorities
    getTicketStates: () => get(`${BASE_URL}/ticket_states`),
    getTicketPriorities: () => get(`${BASE_URL}/ticket_priorities`),
    
    // Attachments
    createTicketAttachment: (data) => 
        post(`${BASE_URL}/tickets/attachments`, data),
    createArticleAttachment: (data) => 
        post(`${BASE_URL}/tickets/articles/attachments`, data),

    createTicketWithAttachments: async (formData) => {
        const response = await postFormData(`${BASE_URL}/tickets/attachments`, formData);
        return response.data;
    },

    createArticleWithAttachments: async (formData) => {
        const response = await postFormData(`${BASE_URL}/tickets/articles/attachments`, formData);
        return response.data;
    },

    getArticleAttachment: async (ticketId, articleId, attachmentId) => {
        const response = await get(
            `${BASE_URL}/${ticketId}/${articleId}/${attachmentId}`,
            { responseType: 'blob' }
        );
        
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    },

    getArticleAttachmentUrl: async (ticketId, articleId, attachmentId) => {
        const response = await get(
            `${BASE_URL}/${ticketId}/${articleId}/${attachmentId}`,
            { responseType: 'blob' }
        );
        return window.URL.createObjectURL(new Blob([response.data]));
    }
};
