import React from 'react';

import Logo from 'assets/images/logo-black.png';
import { useFormik } from 'formik';
import withAuthRedirect from 'hocs/withAuthRedirect';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, FormFeedback, Input, Label, Spinner } from 'reactstrap';
import * as Yup from 'yup';

import PasswordInput from '../../components/Custom/passwordInput';

import { SUPPORT_LINK } from 'constants/links';

import { t } from '../../i18n';
import { loginUser } from '../../store/actions';
import s from './scss/AuthPages.module.scss';

const Login = (props) => {
  const dispatch = useDispatch();
  const isDesktop = useSelector((state) => state.Layout.isDesktop);
  const validation = useFormik({
    enableReinitialize: false,

    initialValues: {
      identifier: '',
      password: '',
      rememberMe: true,
    },
    validationSchema: Yup.object({
      identifier: Yup.string().required(t('common_email_hint')),
      password: Yup.string().required(t('common_password_hint')),
    }),
    onSubmit: (values) => {
      const trimmedValues = {
        ...values,
        password: values.password.trim(),
        identifier: values.identifier.trim(),
      };

      if (isDesktop) {
        console.log('desktop');
        window.api.send('login', {
          username: trimmedValues.identifier,
          password: trimmedValues.password,
        });
      }
      dispatch(loginUser(trimmedValues, props.history));
    },
  });

  const { error, loading } = useSelector((state) => ({
    error: state.Login.error,
    loading: state.Login.loading,
  }));

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t('auth_login_meta_title')}</title>
      </MetaTags>
      <div className={s.wrapper}>
        <div className={s.formWrapper}>
          <img src={Logo} alt="logo" className={s.logo} />
          <div>
            <h4 className={s.title}>{t('auth_login_welcome')}</h4>
            <p className={s.subtitle}>{t('auth_enter_platform')}</p>
          </div>

          <Form
            className={s.form}
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
            }}>
            {error ? <div className="alert-warning">{`${t(error)}`}</div> : null}
            <div className={s.inputBlock}>
              <div>
                <Label className={s.formLabel}>{`${t('common_email')}`}</Label>
                <Input
                  name="identifier"
                  className="auth-input"
                  placeholder={`${t('common_email_placeholder')}`}
                  type="text"
                  id="username"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.identifier || ''}
                  invalid={!!(validation.touched.identifier && validation.errors.identifier)}
                />
                {validation.touched.identifier && validation.errors.identifier ? (
                  <FormFeedback type="invalid" className="auth-input-warning-hint">
                    {validation.errors.identifier}
                  </FormFeedback>
                ) : null}
              </div>
              <div>
                <div className={s.forgotWrapper}>
                  <Label className={s.formLabel} htmlFor="password">
                    {`${t('common_password')}`}
                  </Label>
                  <Link to="/forgot-password" className={s.forgotTxt}>
                    {t('auth_forgot_password')}
                  </Link>
                </div>

                <PasswordInput
                  validation={validation}
                  name="password"
                  id="password"
                  placeholder={`${t('common_password_placeholder')}`}
                  invalid={!!(validation.touched.password && validation.errors.password)}
                />
              </div>
            </div>

            <div className={s.rememberWrapper}>
              <Input
                type="checkbox"
                className="form-check-input"
                id="auth-remember-check"
                name="rememberMe"
                style={{ margin: 0 }}
                checked={validation.values.rememberMe}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />
              <label className="form-check-label" htmlFor="auth-remember-check">
                {`${t('auth_login_remember_me')}`}
              </label>
            </div>

            <div className={s.actions}>
              <button className={s.submitButton} type="submit" disabled={loading}>
                {loading ? (
                  <div className="d-flex gap-2 justify-content-center align-items-center">
                    <Spinner className="spinner" /> {t('auth_login')}
                  </div>
                ) : (
                  t('auth_login')
                )}
              </button>
            </div>

            <div className={s.footerLinks}>
              <p className={s.bottomText}>
                {t('auth_login_no_account')}
                <Link to="register" className={s.bottomTextLink}>
                  {' '}
                  {t('auth_login_register')}
                </Link>
              </p>

              <div className={s.bottomText}>
                <Trans
                  i18nKey="auth_login_support"
                  components={{
                    supportLink: (
                      <a href={SUPPORT_LINK} target="_blank" style={{ color: '#0c958f' }} />
                    ),
                  }}
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

Login.propTypes = {
  history: PropTypes.object,
};

export default withAuthRedirect(Login);
