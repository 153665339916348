import React from 'react';
import { Modal } from 'reactstrap';

const ImagePreview = ({ isOpen, toggle, imageUrl }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
      <div className="image-preview-container">
        <button className="close-button" onClick={toggle}>
          <i className="bx bx-x"></i>
        </button>
        <img src={imageUrl} alt="" className="preview-image" />
      </div>
    </Modal>
  );
};

export default ImagePreview;
