import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Badge, ListGroup, ListGroupItem } from 'reactstrap';
import { format } from 'date-fns';
import { mockStatuses, mockPriorities } from '../../../mocks/supportData';

const TicketList = ({ tickets, loading, activeTab, states, priorities }) => {
    const { t } = useTranslation();
    const history = useHistory();
    console.log(tickets);

    const filterTickets = (tickets) => {
        if (!tickets) return [];
        
        const tabStatusMap = {
            'recent': [1, 2, 3, 6], // new, open, pending reminder, pending close
            'open': [2], 
            'closed': [4, 5] // closed, merged
        };

        const allowedStatuses = tabStatusMap[activeTab];
        if (!allowedStatuses) return tickets;

        return tickets.filter(ticket => allowedStatuses.includes(ticket?.state_id));
    };

    const filteredTickets = filterTickets(tickets);

    const handleTicketClick = (ticketId) => {
        history.push(`/support/ticket/${ticketId}`);
    };

    if (loading) {
        return (
            <div className="placeholder-glow">
                {[1, 2, 3].map((i) => (
                    <div key={i} className="mb-3">
                        <div className="placeholder col-12 bg-light rounded" style={{ height: '80px' }} />
                    </div>
                ))}
            </div>
        );
    }

    if (!filteredTickets?.length) {
        return (
            <div className="text-center py-5">
                <h5 className="text-muted mb-0">{t('No tickets found')}</h5>
            </div>
        );
    }

    const getTicketState = (stateId) => {
        const state = states?.find(s => s.id === stateId);
        return state?.name || 'unknown';
    };

    const getTicketPriority = (priorityId) => {
        const priority = priorities?.find(p => p.id === priorityId);
        return priority?.name || 'normal';
    };

    return (
        <ListGroup className="ticket-list">
            {filteredTickets.map((ticket) => (
                <ListGroupItem
                    key={ticket.id}
                    action
                    onClick={() => handleTicketClick(ticket.id)}
                    className="border rounded mb-2 ticket-item"
                >
                    <div className="d-flex justify-content-between align-items-start">
                        <div>
                            <div className="mb-1">
                                <Badge 
                                    color="light" 
                                    className="me-2 text-secondary"
                                >
                                    #{ticket.number}
                                </Badge>
                                <Badge 
                                    color={ticket.state_id === 2 ? 'success' : 'secondary'} 
                                    className="me-2"
                                >
                                    {t(`support_status_${getTicketState(ticket.state_id)}`)}
                                </Badge>
                                <Badge color="info">
                                    {t(`support_priorities_${getTicketPriority(ticket.priority_id)}`)}
                                </Badge>
                            </div>
                            <h5 className="mb-1">{ticket.title}</h5>
                        </div>
                        <div className="text-end">
                            <div className="text-muted mb-1">
                                {format(new Date(ticket.created_at), 'dd.MM.yyyy, HH:mm')}
                            </div>
                            <div className="text-muted small">
                                {t('support_messages_count', { count: ticket?.article_count || 0 })}
                            </div>
                        </div>
                    </div>
                </ListGroupItem>
            ))}
        </ListGroup>
    );
};

export default TicketList;
