import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import parse from 'html-react-parser';
import { t } from 'i18next';
import { ReactComponent as EmptyIcon } from 'assets/images/icons/empty.svg';
import { getNotifications } from 'store/actions';
import './Notifications.scss';

const NotificationSkeleton = () => (
  <div className="notification-item-wrapper">
    <div className="notification_leftside">
      <div className="skeleton-line" style={{ width: '80%', height: '24px', marginBottom: '12px' }} />
      <div className="skeleton-line" style={{ width: '100%', height: '16px' }} />
    </div>
    <div className="notification_rightside">
      <div className="skeleton-line" style={{ width: '100%', height: '16px', marginBottom: '8px' }} />
      <div className="skeleton-line" style={{ width: '80%', height: '16px' }} />
    </div>
  </div>
);

const Notifications = ({ notifications }) => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.Layout.language);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getNotifications({
        locale: language?.toLowerCase() || 'en',
        take: 20,
        skip: 0,
      })
    );
    // Используем setTimeout чтобы дать время для загрузки данных
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [dispatch, language]);

  const sortedNotifications = React.useMemo(() => {
    if (!notifications?.items?.length) {
      return [];
    }
    return [...notifications.items].sort((a, b) =>
      moment(b.notification_date).diff(moment(a.notification_date))
    );
  }, [notifications?.items]);

  const hasNotifications = sortedNotifications.length > 0;

  return (
    <>
      <div className="notification_title_block">
        <h4 className="dashboard-block-title">{t('notification_title')}</h4>
      </div>
      <div className="notification-list-wrapper">
        {loading ? (
          <>
            <NotificationSkeleton />
            <NotificationSkeleton />
            <NotificationSkeleton />
          </>
        ) : (
          <>
            {!hasNotifications ? (
              <div className="referrals-zero" style={{ minHeight: 450 }}>
                <div>
                  <EmptyIcon />
                  <p>{t('notification_not_have_data')}</p>
                </div>
              </div>
            ) : (
              sortedNotifications.map((n) => (
                <div className="notification-item-wrapper" key={n.id}>
                  <div className="notification_leftside">
                    <h4 className="notification_title">{n.notification_title}</h4>
                    <p className="notification_text">{parse(String(n.notification_text) || '')}</p>
                  </div>
                  <div className="notification_rightside">
                    <p className="notification_type">{n.notifications_type?.title ?? ''}</p>
                    {n.notification_date && (
                      <p className="notification_date_list">
                        {moment(n.notification_date).format('HH:mm DD.MM.YYYY')}
                      </p>
                    )}
                  </div>
                </div>
              ))
            )}
            {notifications?.total_items_count > 10 && (
              <div className="d-flex justify-content-center mt-4">
                <Link
                  to="/notifications"
                  style={{
                    fontSize: '16px',
                    color: '#13b9ae',
                    textDecoration: 'none',
                    padding: '4px 20px',
                    border: '2px solid #13b9ae',
                    borderRadius: '4px',
                    transition: 'all 0.3s ease',
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.background = '#13b9ae';
                    e.currentTarget.style.color = 'white';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.background = 'transparent';
                    e.currentTarget.style.color = '#13b9ae';
                  }}
                >
                  {t('notification_view_all')}
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.object,
};

const mapStateToProps = (state) => ({
  notifications: state.Notifications.notifications,
});

export default connect(mapStateToProps)(Notifications);
