import React, { useState } from 'react';
import { useEffect } from 'react';

import incomeImg from 'assets/images/metrics-income.svg';
import classnames from 'classnames';
import { roundToDynamicNumbers } from 'helpers/Utils';
import { get } from 'helpers/api_helper';
import withAdmin from 'hocs/withAdmin';
import { MetaTags } from 'react-meta-tags';
import { Card, CardBody, Container, Nav, NavItem, NavLink, Row } from 'reactstrap';

import Breadcrumbs from '../../components/Common/Breadcrumb';

import { ACCESS } from 'constants/access';

import useTranslation from '../../hooks/useTranslation';
import { metricsNavDisplayedTitles, metricsTableNavItems } from './MetricsContstants';
import PartnersMetricsTable from './tables/PartnersMetricsTable';
import ProjectsMetricsTable from './tables/ProjectsMetricsTable';

const Metrics = () => {
  const t = useTranslation();
  const [projectsTab, setProjectsTab] = useState('year');
  const [usersTab, setUsersTab] = useState('year');
  const [metricsSummary, setMetricsSummary] = useState({});

  const handleMetricsSummary = async () => {
    const response = await get('metrics/summary');
    setMetricsSummary(response);
  };

  useEffect(() => {
    handleMetricsSummary();
  }, []);

  const tabHandler = (state, tabNumber) => {
    return () => {
      if (state === 'project') {
        if (projectsTab !== tabNumber) {
          return setProjectsTab(tabNumber);
        }
      }
      if (state === 'user') {
        if (usersTab !== tabNumber) {
          setUsersTab(tabNumber);
        }
      }
    };
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>{t('metrics_meta_title')}</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          title="Metrics"
          hasBreadcrumbItem={false}
          breadcrumbItem={t('sidebar_metrics_label')}
        />
        <Row>
          <Card>
            <CardBody>
              <h3 className="metrics__platform_data_title">{t('metrics_general_data')}</h3>
              <div className="metrics__platform_data_wrapper">
                <div className="metrics__platform_data_item">
                  {t('metrics_total_income')}
                  <div className="metrics__platform_data_item_content">
                    <img src={incomeImg} />
                    {roundToDynamicNumbers(metricsSummary.platformIncome, 1)}
                  </div>
                </div>
                <div className="metrics__platform_data_item">
                  {t('metrics_active_users')}
                  <div className="metrics__platform_data_item_content">
                    {metricsSummary.activeUsers}
                  </div>
                </div>
                <div className="metrics__platform_data_item">
                  {t('metrics_inactive_users')}
                  <div className="metrics__platform_data_item_content">
                    {metricsSummary.inactiveUsers}
                  </div>
                </div>
                <div className="metrics__platform_data_item">
                  {t('metrics_partners_paid')}
                  <div className="metrics__platform_data_item_content">
                    {roundToDynamicNumbers(metricsSummary.sumAllSendedReferrals, 1)}
                  </div>
                </div>
                <div className="metrics__platform_data_item">
                  {t('metrics_investors_paid')}
                  <div className="metrics__platform_data_item_content">
                    {roundToDynamicNumbers(metricsSummary.sumAllSendedProducts, 1)}
                  </div>
                </div>
                <div className="metrics__platform_data_item">
                  {t('metrics_to_be_paid_seven_day')}
                  <div className="metrics__platform_data_item_content">
                    {roundToDynamicNumbers(metricsSummary.sumAccrualsInTheNextSevenDays, 1)}
                  </div>
                </div>
                <div className="metrics__platform_data_item">
                  {t('metrics_total_to_be_paid')}
                  <div className="metrics__platform_data_item_content">
                    {roundToDynamicNumbers(metricsSummary.allAccruals, 1)}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          {/*TODO uncomment after fixing on server*/}
          {/*<Card>*/}
          {/*  <CardBody>*/}
          {/*    <div className="d-flex justify-content-between flex-wrap-mobile">*/}
          {/*      <div className="metrics__table_title">{t('metrics_best_partners')}</div>*/}
          {/*      <Nav tabs className="nav-tabs-custom align-items-center flex-wrap-mobile">*/}
          {/*        {metricsTableNavItems.map((tabName) => (*/}
          {/*          <NavItem key={`navItem_${tabName}`}>*/}
          {/*            <NavLink*/}
          {/*              style={{ cursor: 'pointer' }}*/}
          {/*              className={classnames({*/}
          {/*                active: usersTab === tabName,*/}
          {/*              })}*/}
          {/*              onClick={tabHandler('user', tabName)}>*/}
          {/*              <span className="d-block">{metricsNavDisplayedTitles(t)[tabName]}</span>*/}
          {/*            </NavLink>*/}
          {/*          </NavItem>*/}
          {/*        ))}*/}
          {/*      </Nav>*/}
          {/*    </div>*/}
          {/*    /!*<PartnersMetricsTable filterBy={usersTab} />*!/*/}
          {/*  </CardBody>*/}
          {/*</Card>*/}
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between flex-wrap-mobile metrics__table_title_wrapper">
                <div className="metrics__table_title">{t('metrics_top_rated_projects')}</div>
                <Nav tabs className="metrics-custom-tabs">
                  {metricsTableNavItems?.map((tabName) => (
                    <NavItem key={`navItem_${tabName}`}>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: projectsTab === tabName,
                        })}
                        onClick={tabHandler('project', tabName)}>
                        <span className="d-block">{metricsNavDisplayedTitles(t)[tabName]}</span>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </div>

              <ProjectsMetricsTable filterBy={projectsTab} />
            </CardBody>
          </Card>
        </Row>
      </Container>
    </div>
  );
};

export default withAdmin(Metrics, ACCESS.metrics);
