import React, { useEffect, useMemo, useState } from 'react';

import { ReactComponent as EmptyIcon } from 'assets/images/icons/empty.svg';
import parse from 'html-react-parser';
import { t } from 'i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';

import { getAccessSelector, getRoleSelector } from '../../store/auth/profile/selectors';
import { getAllNotifications, getNotifications } from 'store/actions';

import Table from 'components/AnotherTable/Table';
import Breadcrumbs from 'components/Common/Breadcrumb';
import EmptyState from 'components/EmptyState';
import PaginationComponent from 'components/PaginationComponent';
import TableSkeleton from 'components/TableSkeleton/TableSkeleton';

import CustomDropdown from './Admin/CustomDropdown/CustomDropdown';
import DeleteModalNotification from './Admin/DeleteModal/DeleteModalNotification';

const Notifications = () => {
  const language = useSelector((state) => state.Layout.language);
  const adminNotifications = useSelector((state) => state.Notifications.adminNotifications);
  const notifications = useSelector((state) => state.Notifications.notifications);
  const dispatch = useDispatch();
  const role = useSelector(getRoleSelector);
  const isAdminAccess = useMemo(
    () => role?.access?.includes('notifications') && role?.name === 'admin',
    [role],
  );
  const notificationsList = isAdminAccess ? adminNotifications : notifications;
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(true);

  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const history = useHistory();

  const editClickHandler = (id) => {
    history.push(`/notifications/edit/${id}`);
  };

  const deleteClickHandler = (id) => {
    setDeleteModal({ isOpen: true, selectedItem: id });
  };

  useEffect(() => {
    if (pagination?.pageNumber) {
      setLoading(true);
      const params = {
        take: pagination.size,
        skip: (pagination.pageNumber - 1) * pagination.size,
        locale: language?.toLowerCase() || 'en',
      };

      if (isAdminAccess) {
        dispatch(getAllNotifications(params));
      } else {
        dispatch(getNotifications(params));
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [isAdminAccess, pagination, language]);

  const columns = useMemo(
    () => [
      {
        dataField: 'notifications_type',
        text: t('notification_type'),
        formatter: (cell, row) => row.notifications_type?.title || '',
      },
      {
        dataField: 'notification_title',
        text: t('notification_item_title'),
      },
      {
        dataField: 'notification_text',
        text: t('notification_text'),
        formatter: (cell) => parse(String(cell) || ''),
      },
      {
        dataField: 'notification_date',
        text: t('notification_date'),
        formatter: (cell) => moment(cell).format('DD.MM.YYYY HH:mm'),
      },
      ...(isAdminAccess
        ? [
            {
              dataField: 'delivery',
              text: t('notification_delivery_type'),
              formatter: (cell, row) => {
                const displayIsSite = row.isSite ? t('common_site') : '';
                const displayIsEmail = row.isEmail
                  ? row.isSite
                    ? `, ${t('common_mail')}`
                    : t('common_mail')
                  : '';
                return (
                  <>
                    {displayIsSite}
                    {displayIsEmail}
                  </>
                );
              },
            },
            {
              dataField: 'action',
              text: '',
              formatter: (cell, row) =>
                isAdminAccess ? (
                  <CustomDropdown
                    id={row.id}
                    editClickHandler={() => editClickHandler(row.id)}
                    deleteClickHandler={() => deleteClickHandler(row.id)}
                  />
                ) : null,
            },
          ]
        : []),
    ],
    [isAdminAccess],
  );

  return (
    <div>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Breadcrumbs
            title={t('notification_title')}
            hasBreadcrumbItem={false}
            breadcrumbItem={t('notification_all')}
          />
          {isAdminAccess && (
            <Link to="/notifications/create">
              <Button color="primary" style={{ background: '#13b9ae', border: 'none' }}>
                {t('notification_create_notification')}
              </Button>
            </Link>
          )}
        </div>
        <Card>
          <CardBody>
            {loading ? (
              <div style={{ marginBottom: '20px' }}>
                <TableSkeleton rowsCount={5} columnsCount={isAdminAccess ? 6 : 4} />
              </div>
            ) : (
              <>
                <Table
                  keyField="id"
                  columns={columns}
                  data={notificationsList?.items}
                  custom={{
                    classes: 'table__adaptive_bordered_between text-start table-without_padding',
                  }}
                />
                {(!notificationsList?.items || notificationsList?.items?.length === 0) && (
                  <EmptyState />
                )}
              </>
            )}
            <PaginationComponent
              active={pagination.pageNumber}
              setPagination={setPagination}
              total={notificationsList?.total_pages_count}
              initialSize={10}
            />
          </CardBody>
        </Card>
      </Container>
      <DeleteModalNotification
        isOpen={deleteModal.isOpen}
        closeModal={() => setDeleteModal({ isOpen: false, selectedItem: null })}
        selectedItemId={deleteModal.selectedItem}
      />
    </div>
  );
};

export default Notifications;
